import revive_payload_client_4sVQNw7RlN from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_jtc2qNDx4l from "/builds/nextory/web/nx-web/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/nextory/web/nx-web/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/builds/nextory/web/nx-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_iGLjBbor9q from "/builds/nextory/web/nx-web/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import i18n_Ug908EZNww from "/builds/nextory/web/nx-web/node_modules/nuxt-site-config/dist/runtime/app/plugins/i18n.js";
import plugin_tbFNToZNim from "/builds/nextory/web/nx-web/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/builds/nextory/web/nx-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/builds/nextory/web/nx-web/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import _01_hummingbird_MpGfvuzJIp from "/builds/nextory/web/nx-web/plugins/01.hummingbird.ts";
import _02_vuex_eETsWCafwP from "/builds/nextory/web/nx-web/plugins/02.vuex.ts";
import _03_auth_0qOenMw9ot from "/builds/nextory/web/nx-web/plugins/03.auth.ts";
import link_d5gJBZWhfl from "/builds/nextory/web/nx-web/plugins/link.ts";
import sentry_client_shVUlIjFLk from "/builds/nextory/web/nx-web/plugins/sentry.client.ts";
import tracker_HVfdfgMm5W from "/builds/nextory/web/nx-web/plugins/tracker.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_jtc2qNDx4l,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_iGLjBbor9q,
  i18n_Ug908EZNww,
  plugin_tbFNToZNim,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  _01_hummingbird_MpGfvuzJIp,
  _02_vuex_eETsWCafwP,
  _03_auth_0qOenMw9ot,
  link_d5gJBZWhfl,
  sentry_client_shVUlIjFLk,
  tracker_HVfdfgMm5W
]