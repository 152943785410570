<template>
  <div>
    <NxHeader />

    <NxErrors :error="error" />

    <LazyNxFooter />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import tracker from '~/mixins/tracker'

export default defineComponent({
  mixins: [tracker],

  props: {
    error: { type: Object, default: null },
  },

  setup() {
    useCustomHead()
  },
})
</script>
