import consola from 'consola'

const logger = consola.withTag('Utils - localizationFormatter')

/**
 * Formats a price in the user's locale.
 * If the price is invalid, logs the error to Sentry and returns an empty string.
 */
export function getLocalizedPrice(price: number, currencyCode: string): string {
  if (!tryUseNuxtApp()) {
    // FIXME: In the nuxt-jsonld module, sometimes the Nuxt instance is not available for no reason at all.
    // Example: /se/book/vi-slog-folje-en-stund-moten-och-minnen-fran-terapirum-och-radio-3569573
    //
    // This bug was introduced when we migrated this function from our context-aware helpers to auto-imported utils.
    //
    // In that case, we return an empty string, which is wrong but at least the page will not crash.
    return ''
  }

  const { $i18n, $sentry } = useNuxtApp()

  try {
    return new Intl.NumberFormat(
      $i18n.localeProperties.value?.language || $i18n.localeProperties.language,
      {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      }
    ).format(price)
  } catch (e) {
    logger.error('Failed to format price', e)

    $sentry.captureException(e)

    return ''
  }
}

/**
 * Formats a date in the user's locale.
 * If the date is invalid, logs the error to Sentry and returns an empty string.
 */
export function getLocalizedDate(
  date: string,
  options?: Intl.DateTimeFormatOptions
): string {
  const { $i18n, $sentry } = useNuxtApp()

  try {
    return new Intl.DateTimeFormat(
      $i18n.localeProperties.value?.language || $i18n.localeProperties.language,
      options
    ).format(Date.parse(date))
  } catch (e) {
    logger.error('Failed to format date', e)

    $sentry.captureException(e)

    return ''
  }
}

/**
 * Converts a timestamp to a formatted date (YYYY-MM-DD).
 */
export function timestampToDate(timestamp: number): string {
  return new Date(timestamp).toISOString().split('T')[0]
}

/**
 * Converts a duration in seconds to an ISO 8601 time duration format.
 */
export function secondsToDurationString(duration: number): string {
  const hours = Math.floor(duration / 3600)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((duration % 3600) / 60)
    .toString()
    .padStart(2, '0')
  const seconds = Math.floor(duration % 60)
    .toString()
    .padStart(2, '0')

  return `PT${hours}H${minutes}M${seconds}S`
}

/**
 * Formats a duration into hours and minutes.
 */
export function formatDuration(sec: number): { hour: number; min: number } {
  const hour = Math.floor(sec / 3600)
  const min = Math.floor((sec % 3600) / 60)

  return { hour, min }
}

/**
 * Formats time spent in the user's locale.
 */
export function getTimeSpent(sec: number): string {
  const { $i18n } = useNuxtApp()
  const { hour, min } = formatDuration(sec)

  if (hour === 0) {
    return $i18n.t('mypages.time_restricted.time_consumed_min', { min })
  }

  return $i18n.t('mypages.time_restricted.time_consumed', { hour, min })
}

/**
 * Parses an ISO locale string into an object with language and country.
 */
export function parseLocaleIso(localeIso: string) {
  const [language, country] = localeIso.split('-')

  return { language, country }
}
