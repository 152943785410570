import type { AxiosPromise } from 'axios'

import type {
  Character,
  PopularCharacters,
} from '../api-types/PopularCharacters'

import BaseApi from '../bridge-api/baseApi'

// BRANDS MEANS CHARACTERS
// here is the API url for the popular characters
// and returns a list of PopularCharacters

export class Brands extends BaseApi {
  getPopularKidsCharacters(
    page: number,
    per: number
  ): AxiosPromise<PopularCharacters> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/brands/all?page=${page}&&per=${per}&profile_type=KIDS`,
    })
  }

  getCharacter(
    characterId: string,
    page: number = 0,
    per: number = 48
  ): AxiosPromise<Character> {
    return this.http({
      method: 'get',
      url: `/discovery/v2/brand/${characterId}?page=${page}&per=${per}&profile_type=KIDS`,
    })
  }

  getProductsByCharacter(
    characterId: string,
    page: number = 0,
    per: number = 48
  ): AxiosPromise<Character> {
    return this.http({
      method: 'get',
      url: `/discovery/v1/brand/${characterId}/products?page=${page}&per=${per}`,
    })
  }
}
