<template>
  <nav :aria-label="$t('global.accessibility.nav.main')">
    <button
      ref="navigationButton"
      class="button-header"
      tabindex="0"
      aria-haspopup="true"
      :aria-expanded="isMenuExpanded ? 'true' : 'false'"
      aria-controls="navigation"
      @click="showMenu"
    >
      <span
        id="main-navigation-label"
        class="icon-24 icon--navigation-menu"
        :class="[hasNavIconsLight ? 'text--sand-200' : 'text--sand-700']"
        data-test="navigation-icon"
      >
        {{ $t('global.accessibility.nav.main') }}
      </span>
    </button>

    <section
      v-show="isMenuExpanded"
      ref="navigationDialog"
      role="dialog"
      aria-modal="true"
      class="main-navigation bg--purple-700"
      aria-labelledby="main-navigation-label"
    >
      <div class="container">
        <div class="d-flex justify-content-end py-24 py-lg-32">
          <button class="button-header" @click="hideMenu">
            <span class="icon-24 icon--close text--sand-200">Close</span>
          </button>
        </div>
        <div class="row">
          <div class="col-12 px-24 col-md-4 offset-md-8 col-lg-3 offset-lg-9">
            <!-- data-allow-mismatch is here to allow the mismatch of the logout button, since it relies on client's local storage to be shown or not -->
            <ul
              class="navigation-list text--sand-200 my-24"
              role="menu"
              data-allow-mismatch
            >
              <li
                class="navigation-list_items mb-16"
                role="menuitem"
                @click="hideMenu"
              >
                <h2 class="headline--medium">
                  <NuxtLink
                    ref="firstLinkElement"
                    :to="localePath({ name: 'index' })"
                    :aria-label="$t('homepage.menu_home')"
                    class="text--link text-decoration-none d-block"
                  >
                    {{ $t('homepage.menu_home') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                class="navigation-list_items mb-16"
                role="menuitem"
                @click="hideMenu"
              >
                <h2 class="headline--medium">
                  <NuxtLink
                    data-test="categories-link"
                    tabindex="0"
                    :to="localePath({ name: 'categories-books' })"
                    class="text--link text-decoration-none d-block"
                  >
                    {{ $t('homepage.menu_categories') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                class="navigation-list_items mb-16"
                role="menuitem"
                @click="hideMenu"
              >
                <h2 class="headline--medium">
                  <NuxtLink
                    tabindex="0"
                    :to="localePath({ name: 'audiobooks' })"
                    class="text--link text-decoration-none d-block"
                    data-test="audiobook-link"
                  >
                    {{ $t('mypages.time_consumption.faq.subtitle1') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                class="navigation-list_items mb-16"
                role="menuitem"
                @click="hideMenu"
              >
                <h2 class="headline--medium">
                  <NuxtLink
                    tabindex="0"
                    :to="localePath({ name: 'e-books' })"
                    class="text--link text-decoration-none d-block"
                    data-test="eBook-link"
                  >
                    {{ $t('mypages.time_consumption.faq.subtitle2') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                v-if="$store.state.webConfig.config?.features.hasMagazines"
                class="navigation-list_items mb-16"
                role="menuitem"
                @click="hideMenu"
              >
                <h2 class="headline--medium">
                  <NuxtLink
                    tabindex="0"
                    :to="localePath({ name: 'magazines' })"
                    class="text--link text-decoration-none d-block"
                    data-test="magazines-link"
                  >
                    {{ $t('homepage.menu_magazines') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                class="navigation-list_items mb-16"
                role="menuitem"
                @click="hideMenu"
              >
                <h2 class="headline--medium">
                  <NuxtLink
                    tabindex="0"
                    :to="localePath({ name: 'for-kids' })"
                    class="text--link text-decoration-none d-block"
                    data-test="for-kids-link"
                  >
                    {{ $t('homepage.menu_for_kids') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                class="navigation-list_items mb-32"
                role="menuitem"
                @click="hideMenu"
              >
                <h2 class="headline--medium">
                  <a
                    tabindex="0"
                    :href="$t('global.link_faq.url')"
                    class="text--link text-decoration-none d-block"
                  >
                    {{ $t('homepage.menu_help') }}
                  </a>
                </h2>
              </li>
              <li role="menuitem" class="navigation-list_items mb-8">
                <h2 class="text--body">
                  <NuxtLink
                    :to="localePath({ name: 'download-apps' })"
                    :aria-label="$t('homepage.menu_download_app')"
                    class="text--link text-decoration-none d-block"
                  >
                    {{ $t('homepage.menu_download_app') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                v-if="canRedeemCampaign"
                role="menuitem"
                class="navigation-list_items mb-8"
              >
                <h2 class="text--body">
                  <NuxtLink
                    data-test="redeem-campaign"
                    tabindex="0"
                    :to="localePath({ name: 'register-campaign-activate' })"
                    class="text--link text-decoration-none"
                  >
                    {{ $t('homepage.menu_campaign_code') }}
                  </NuxtLink>
                </h2>
              </li>
              <li
                v-if="canRedeemGiftcard"
                role="menuitem"
                class="navigation-list_items mb-8"
              >
                <h2 class="text--body">
                  <NuxtLink
                    tabindex="0"
                    :to="localePath({ name: 'register-giftcard-info' })"
                    class="text--link text-decoration-none"
                    data-test="redeem-giftcard"
                  >
                    {{ $t('homepage.menu_redeem_giftcard') }}
                  </NuxtLink>
                </h2>
              </li>
              <li v-if="!$auth.loggedIn" role="menuitem" class="mt-24 mb-8">
                <NuxtLink
                  :to="localePath({ name: 'register' })"
                  :aria-label="
                    $t($hummingbird.marketProperties.cta, {
                      trialDays: $store.state.webConfig.config?.trialDays,
                    })
                  "
                  class="button button--primary w-100"
                >
                  {{
                    $t($hummingbird.marketProperties.cta, {
                      trialDays: $store.state.webConfig.config?.trialDays,
                    })
                  }}
                </NuxtLink>
              </li>
              <li v-if="!$auth.loggedIn" role="menuitem">
                <NuxtLink
                  :to="localePath({ name: 'profile-login' })"
                  :aria-label="$t('global.button_login')"
                  class="button button--secondary w-100 mb-32"
                  data-test="logged-user-login-btn"
                >
                  {{ $t('global.button_login') }}
                </NuxtLink>
              </li>
              <!-- logout button for logged users -->
              <li
                v-if="$auth.loggedIn && isAllowedToLogout"
                role="menuitem"
                class="mt-24 mb-8"
              >
                <button
                  :aria-label="$t('global.button_logout')"
                  class="button button--primary w-100"
                  data-test="logout-button"
                  @click="logoutUserAndClose"
                >
                  {{ $t('global.button_logout') }}
                </button>
              </li>
              <DevOnly v-if="$auth.loggedIn && !isAllowedToLogout">
                <li
                  role="menuitem"
                  class="mt-24 mb-8 bg--pink-500 p-12 br-5 text-center"
                >
                  <span>🚨 Not allowed to log out</span>
                  <br />
                  <span class="text--meta">
                    Clear your local storage if it's not intended.
                  </span>
                </li>
              </DevOnly>
              <!-- end logout button -->
              <li role="menuitem">
                <p role="none" class="text--meta mb-8">
                  {{ $t('homepage.menu.language_picker') }}
                </p>
                <div
                  v-for="market in sortedMarkets"
                  :key="market.country"
                  :lang="market.lang"
                  class="hstack my-12 bg--purple-600 px-12 py-4 br-5"
                >
                  <p class="mb-0">
                    {{ market.country }}
                  </p>
                  <ul role="menu" class="d-flex navigation-list ms-auto">
                    <li
                      v-for="language in filteredLanguages(market.languages)"
                      :key="language.locale"
                      role="menuitem"
                      class="navigation-list_items"
                    >
                      <a
                        class="language-link text--link text--meta ms-8"
                        :href="getChangeLocalePath(language.locale)"
                        :data-test="`language-picker-${language.locale}`"
                        :class="{
                          'language-link--active':
                            $i18n.locale === language.locale,
                          'language-link--inactive':
                            $i18n.locale !== language.locale,
                        }"
                      >
                        {{ language.label }}
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </nav>
</template>

<script>
// FIXME: Debug languages are temporarily disabled to ease on nuxt-i18n performance (too many routes slows it down)
const debugLabel = '(debug)'

export default defineNuxtComponent({
  setup() {
    const { focusTrap } = useFocusTrap()
    const isAllowedToLogout = useIsAllowedToLogOut()

    return {
      focusTrap,
      isAllowedToLogout,
    }
  },

  props: {
    hasNavIconsLight: { type: Boolean, default: false },
  },

  data() {
    return {
      isMenuExpanded: false,
      isFullscreenOpened: true,
      selectedElement: 0,
      markets: [
        {
          country: '🇫🇷 France',
          code: 'fr',
          lang: 'fr',
          api: 'legacy',
          languages: [
            {
              locale: 'fr',
              label: 'FR',
            },
            {
              locale: 'fr-en',
              label: 'EN',
            },
          ],
        },
        {
          country: '🇧🇪 Belgique',
          code: 'be',
          lang: 'fr',
          api: 'legacy',
          languages: [
            {
              locale: 'be-fr',
              label: 'FR',
            },
            {
              locale: 'be-en',
              label: 'EN',
            },
          ],
        },
        {
          country: '🇳🇱 Nederland',
          code: 'nl',
          lang: 'nl',
          api: 'modern',
          languages: [
            {
              locale: 'nl',
              label: 'NL',
            },
            {
              locale: 'nl-en',
              label: 'EN',
            },
            // {
            //   locale: 'nl-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇩🇪 Deutschland',
          code: 'de',
          lang: 'de',
          api: 'modern',
          languages: [
            {
              locale: 'de',
              label: 'DE',
            },
            {
              locale: 'de-en',
              label: 'EN',
            },
            // {
            //   locale: 'de-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇸🇪 Sverige',
          code: 'se',
          lang: 'sv',
          api: 'modern',
          languages: [
            {
              locale: 'se',
              label: 'SE',
            },
            {
              locale: 'se-en',
              label: 'EN',
            },
            // {
            //   locale: 'se-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇳🇴 Norge',
          code: 'no',
          lang: 'nb',
          api: 'modern',
          languages: [
            {
              locale: 'no',
              label: 'NO',
            },
            {
              locale: 'no-en',
              label: 'EN',
            },
            // {
            //   locale: 'no-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇩🇰 Danmark',
          code: 'dk',
          lang: 'da',
          api: 'modern',
          languages: [
            {
              locale: 'dk',
              label: 'DK',
            },
            {
              locale: 'dk-en',
              label: 'EN',
            },
            // {
            //   locale: 'dk-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇨🇭 Schweiz',
          code: 'ch',
          lang: 'de',
          api: 'modern',
          languages: [
            {
              locale: 'ch',
              label: 'DE',
            },
            {
              locale: 'ch-en',
              label: 'EN',
            },
            // {
            //   locale: 'ch-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇫🇮 Suomi',
          code: 'fi',
          lang: 'fi',
          api: 'modern',
          languages: [
            {
              locale: 'fi',
              label: 'FI',
            },
            {
              locale: 'fi-en',
              label: 'EN',
            },
            // {
            //   locale: 'fi-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇦🇹 Österreich',
          code: 'at',
          lang: 'de',
          api: 'modern',
          languages: [
            {
              locale: 'at',
              label: 'AT',
            },
            {
              locale: 'at-en',
              label: 'EN',
            },
            // {
            //   locale: 'at-lk',
            //   label: debugLabel,
            // },
          ],
        },
        {
          country: '🇪🇸 España',
          code: 'es',
          lang: 'es',
          api: 'modern',
          languages: [
            {
              locale: 'es',
              label: 'ES',
            },
            {
              locale: 'es-en',
              label: 'EN',
            },
            // {
            //   locale: 'es-lk',
            //   label: debugLabel,
            // },
          ],
        },
      ],
    }
  },

  computed: {
    // Current locale market is returned first depending on the country.
    sortedMarkets() {
      const currentCountry = this.$nuxt.$i18n.locale.value.slice(0, 2)
      const currentMarket = this.markets.find(
        market => market.code === currentCountry
      )

      // Safety check: if no market founds, return the whole list
      if (!currentMarket) {
        return this.markets
      }

      // Remove current market from the list and sort the rest, ignoring country emojis.
      const marketName = market => market.country.replace(/\p{Emoji}/gu, '')
      const otherMarkets = this.markets
        .filter(() => {
          // If user is logged in, hide other markets
          return !this.$auth.loggedIn
        })
        .filter(market => market.code !== currentCountry)
        .sort((a, b) =>
          marketName(a).localeCompare(
            marketName(b),
            'en' /* keeping the same order on server and client sides */
          )
        )

      return [currentMarket, ...otherMarkets].filter(
        market =>
          // avoids errors when no market is found for the current locale
          typeof market !== 'undefined'
      )
    },

    canRedeemGiftcard() {
      return !(
        this.$auth.loggedIn &&
        this.$auth.user.disallowed_actions?.includes('REDEEM_GIFTCARD')
      )
    },

    canRedeemCampaign() {
      return !(
        this.$auth.loggedIn &&
        this.$auth.user.disallowed_actions?.includes('REDEEM_CAMPAIGN')
      )
    },
  },

  mounted() {
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          window.addEventListener('click', this.close, false)
          window.addEventListener('focusin', this.close, false)
          window.addEventListener('keydown', this.close, false)
        } else {
          window.removeEventListener('click', this.close, false)
          window.removeEventListener('focusin', this.close, false)
          window.removeEventListener('keydown', this.close, false)
        }
      },
      { threshold: 0 }
    )

    this.observer?.observe(this.$refs.navigationDialog)
  },

  beforeUnmount() {
    // Making sure body never keeps the overflow-hidden class when switching pages
    this.$store.dispatch('setAsFullscreenComponent', false)

    this.observer?.disconnect()
  },

  methods: {
    getChangeLocalePath(locale) {
      const destinationPage = this.switchLocalePath(locale)
      const currentPath = this.$route.path

      // if both are equal, it means the destination page doesn't exist (NuxtI18n won't tell us directly), so we return the root path
      if (
        destinationPage === currentPath &&
        locale !== this.$nuxt.$i18n.locale.value
      ) {
        return `/${locale}`
      }

      return destinationPage
    },

    close(e) {
      if (!this.$el?.contains(e.target)) {
        this.hideMenu()
      }

      if (e.key === 'Escape') {
        this.hideMenu()
      }

      // Keyboard focus trap inside the dialog
      if (e.key === 'Tab') {
        const dialog = this.$refs.navigationDialog
        this.focusTrap(dialog, e)
      }
    },

    showMenu() {
      this.isMenuExpanded = true

      this.$nextTick(() => {
        // A11Y: Focus on the first link of the menu
        this.$refs.firstLinkElement?.$el?.focus()
      })

      this.$store.dispatch('setAsFullscreenComponent', true)
    },

    hideMenu() {
      this.isMenuExpanded = false
      this.selectedElement = 0
      this.$store.dispatch('setAsFullscreenComponent', false)

      // A11Y: Focus back to dialog trigger button
      this.$refs.navigationButton.focus()
    },

    logoutUserAndClose() {
      this.$auth.logout().then(() => {
        this.hideMenu()
      })
    },

    filteredLanguages(languages) {
      if (process.env.ENVIRONMENT === 'production') {
        // debug languages are not available in production
        return languages.filter(language => language.label !== debugLabel)
      }

      return languages
    },
  },
})
</script>

<style lang="scss">
.language-link {
  background: transparent;
  border: none;

  &--inactive {
    text-decoration: underline;
    color: $purple-300;
  }

  &--active {
    text-decoration: none;
  }
}
</style>
