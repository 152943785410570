<template>
  <NuxtLink
    :to="localePath(bookSlug)"
    class="book-card d-block text-decoration-none"
    :class="{
      'book-card--dark': theme === 'dark',
      'mb-32': !forKids,
    }"
  >
    <div class="position-relative">
      <!-- Single cover view -->
      <div
        v-if="!shouldShowGrid"
        class="book-card__cover-wrapper overflow-hidden br-5 mb-8"
        :class="{
          'bg--white': theme === 'light',
          'bg--purple-800': theme === 'dark',
          'br-10': forKids,
        }"
      >
        <img
          :src="bookCoverUrl"
          class="book-card__cover-image"
          data-test="book-cover"
          alt=""
          width="250"
          height="250"
          :fetchpriority="fetchPriority"
          :loading="lazyLoading"
        />
      </div>

      <!-- Grid view for 4+ books -->
      <div
        v-else
        class="book-card__grid-wrapper overflow-hidden p-8 mb-8 br-5"
        data-test="book-cover"
        :class="{
          'bg--white': theme === 'light',
          'bg--purple-800': theme === 'dark',
          'br-10': forKids,
        }"
        role="group"
        :aria-label="book.title"
      >
        <div class="book-card__grid">
          <img
            v-for="(cover, i) in gridCovers"
            :key="i"
            :src="cover"
            class="book-card__grid-image"
            alt=""
            width="135"
            height="135"
            :fetchpriority="fetchPriority"
            :loading="lazyLoading"
          />
        </div>
      </div>

      <span
        v-if="book.is_adult_book && !$auth.loggedIn"
        class="book-card__cover-overlay text--body-small text--sand-200 position-absolute top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center text-center p-12"
      >
        {{ $t('catalogue.book.cover_warning') }}
      </span>
    </div>

    <div v-if="!showOnlyCover" class="book-card__title">
      <div class="book-card__notation mb-4">
        <StarRating :rating="bookRating" />
      </div>
      <h2
        class="headline--body mb-4 book-card__truncated-title"
        data-test="book-title"
      >
        {{ book.title }}
      </h2>
      <h3 class="text--meta mb-8 book-card__truncated-authors">
        {{ bookAuthors }}
      </h3>
      <div class="d-flex">
        <span
          v-for="(format, bookIndex) in book.formats"
          :key="bookIndex"
          class="icon-16 me-4"
          :class="[
            ['epub', 'pdf'].includes(format.type)
              ? 'icon--ebook'
              : 'icon--audiobook',
          ]"
        >
          {{ ['epub', 'pdf'].includes(format.type) ? 'book' : 'audiobook' }}
        </span>
      </div>
    </div>
    <h2
      v-else
      class="headline--body mb-4 book-card__truncated-title"
      data-test="book-title"
    >
      {{ book.title }}
    </h2>
  </NuxtLink>
</template>

<script setup>
const props = defineProps({
  book: {
    type: Object,
    required: true,
  },

  theme: {
    type: String,
    default: 'light',
    validator: value => ['light', 'dark'].includes(value),
  },

  showOnlyCover: {
    type: Boolean,
    default: false,
  },

  forKids: {
    type: Boolean,
    default: false,
  },

  pagePath: {
    type: String,
    default: 'book-slug',
  },

  index: {
    /**
     * The index of the book in the list let us put image loading priorities in a single place,
     * the first ones being the most important.
     * Objective here is to improve the Largest Contentful Paint of the page.
     *
     * This will affect the fecthpriority and lazy loading of the image.
     */
    type: Number,
    default: 0,
  },
})

const { $auth } = useNuxtApp()

const isLoading = ref(true)

// Computed properties
const fetchPriority = computed(() => {
  if (props.index < 3) return 'high'
  if (props.index < 6) return 'medium'

  return 'low'
})

const lazyLoading = computed(() => (props.index < 6 ? 'eager' : 'lazy'))

const shouldShowGrid = computed(
  () => props.book?.images && props.book.images.length >= 4
)

const bookCoverUrl = computed(() => {
  // For single cover display
  if (!shouldShowGrid.value && props.book?.images?.length > 0) {
    return props.book.images[0].toString()
  }

  // for the product books, we have a different way to get the cover
  let coverImg = getRecommendedCoverFromFormats(props.book.formats)?.img_url

  if (coverImg) {
    const coverUrl = new URL(coverImg)

    // Note: Setting both will constraint the size, without changing the aspect ratio
    coverUrl.searchParams.set('w', '250')
    coverUrl.searchParams.set('h', '250')

    if (props.book.is_adult_book && !$auth.loggedIn) {
      // Adult books have a special filter, so we can put a warning on top of it 🔞
      // For now, it's only for logged-out users.

      // - It's blurred
      coverUrl.searchParams.set('blur', '200')
      // - Aspect ratio is forced to 1:1
      coverUrl.searchParams.set('ar', '1')
      coverUrl.searchParams.set('fit', 'crop')
      // - Brightness and saturation are reduced
      coverUrl.searchParams.set('bri', '-60')
      coverUrl.searchParams.set('sat', '-45')
    }
    coverImg = coverUrl.toString()
  }

  return coverImg
})

const bookAuthors = computed(() => {
  if (!props.book.authors) return ''

  return props.book.authors.map(author => author.name).join(', ')
})

const bookRating = computed(() => Math.round(props.book.average_rating) || 0)

const bookSlug = computed(() => {
  return {
    name: props.pagePath,
    params: {
      slug: createSlug(props.book.title, props.book.id),
    },
  }
})

const gridCovers = computed(() => {
  if (!shouldShowGrid.value) return []

  return props.book.images.slice(0, 4).map(image => {
    const coverUrl = new URL(image.toString())
    coverUrl.searchParams.set('w', '135')
    coverUrl.searchParams.set('h', '135')

    // Until we have all characters with a proper art work, we need to crop the images for our grid view
    coverUrl.searchParams.set('fit', 'crop')
    coverUrl.searchParams.set('crop', 'top')

    return coverUrl.toString()
  })
})

// Lifecycle hooks
onMounted(() => {
  isLoading.value = false
})
</script>

<style lang="scss">
@media (prefers-reduced-motion: reduce) {
  .book-card {
    &:hover {
      .book-card__cover-overlay {
        transform: none !important;
      }
    }
  }
}

.book-card {
  color: $sand-800;
  transition: color 250ms;

  // .book-card__cover-image
  &__cover-image {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  // .book-card__grid-image
  &__grid-image {
    aspect-ratio: 1 / 1;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.5rem;
  }

  // .book-card__cover-wrapper
  &__cover-wrapper,
  &__grid-wrapper {
    box-shadow: rgba(0, 0, 0, 0.3) 0 0.8rem 0.8rem -0.8rem;
    transition: transform 250ms ease-out;

    .book-card__cover-image,
    .book-card__grid-image {
      transition: filter 250ms ease-out;
    }
  }

  // .book-card__cover-overlay
  &__cover-overlay {
    opacity: 1;
    transition: opacity 250ms ease-out;
  }

  // .book-card__grid
  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
  }

  // .book-card--grid
  &--grid {
    .book-card__grid-wrapper {
      aspect-ratio: 1 / 1;
    }
  }

  &:hover,
  &:focus,
  &:focus-visible {
    // When hovering over the card, the card scales up
    .book-card__cover-wrapper,
    .book-card__grid-wrapper {
      transform: scale(1.05);

      // When hovering over the card, the cover image become slightly darker
      .book-card__cover-image,
      .book-card__grid-image {
        filter: brightness(70%);
      }
    }

    .book-card__cover-overlay {
      opacity: 0.7;
    }
  }

  &--dark {
    color: $white;

    &:hover {
      color: $gray-400;
    }
  }

  // .book-card__truncated-title
  &__truncated-title {
    display: -webkit-box;
    // line-height is 2rem, so 3 lines will be 6rem
    max-height: 6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }

  // .book-card__truncated-authors
  &__truncated-authors {
    display: -webkit-box;
    // line-height is 2rem, so 1 lines will be 2rem
    max-height: 2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
  }
}
</style>
