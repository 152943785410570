import consola from 'consola'

const logger = consola.withTag('middleware/restore-external-token.global')

const LOCALSTORAGE_KEY_EXTERNAL_TRANSACTION_TOKEN =
  'app_funnel.external_transaction_token'
const LOCALSTORAGE_KEY_PLATFORM = 'app_funnel.platform'

/**
 * Middleware to restore funnel parameters (external_transaction_token and platform) from LocalStorage
 * and append them to the query string if missing. Only activates for localized register routes.
 *
 * This ensures users returning to the app funnel flow can continue where they left off.
 */
export default defineNuxtRouteMiddleware(to => {
  // Skip on server side or non-register routes
  const routeName = to.name as string
  if (import.meta.server || !routeName?.startsWith('register-')) {
    return
  }

  const { query } = to
  const hasTokenInQuery = Boolean(query.external_transaction_token)
  const hasPlatformInQuery = Boolean(query.platform)

  // If either parameter exists in query, persist to LocalStorage and exit
  if (hasTokenInQuery || hasPlatformInQuery) {
    if (query.external_transaction_token) {
      localStorage.setItem(
        LOCALSTORAGE_KEY_EXTERNAL_TRANSACTION_TOKEN,
        query.external_transaction_token as string
      )
    }
    if (query.platform) {
      localStorage.setItem(LOCALSTORAGE_KEY_PLATFORM, query.platform as string)
    }

    return
  }

  // Attempt to restore parameters from LocalStorage
  const storedToken = localStorage.getItem(
    LOCALSTORAGE_KEY_EXTERNAL_TRANSACTION_TOKEN
  )
  const storedPlatform = localStorage.getItem(LOCALSTORAGE_KEY_PLATFORM)

  if (storedToken && storedPlatform) {
    logger.info('Restoring funnel parameters from LocalStorage', {
      token: storedToken,
      platform: storedPlatform,
    })

    return navigateTo({
      ...to,
      query: {
        ...to.query,
        external_transaction_token: storedToken,
        platform: storedPlatform,
      },
      replace: true,
    })
  }
})
