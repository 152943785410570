/**
 * Some users are not allowed to log out.
 * This is needed in some flows, for example if the user is in a webview from the app funnels.
 */
export default (): Ref<boolean> => {
  const allowed = ref(true)

  if (import.meta.client) {
    const hasExternalTransactionToken = localStorage.getItem(
      'app_funnel.external_transaction_token'
    )
    if (hasExternalTransactionToken) {
      allowed.value = false
    }
  }

  return allowed
}
