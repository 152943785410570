/**
 * Focus Trap functionality for a dialog, menu or search element (all dialog).
 * It ensures that the focus stays within the dialog when navigating with the keyboard.
 *
 * @returns {Object} An object containing the `focusTrap` function.
 */
export function useFocusTrap() {
  /**
   * Checks if an element is visible and focusable.
   *
   * @param {HTMLElement} element - The element to check.
   * @returns {boolean} True if the element is visible and focusable, false otherwise.
   */
  const isVisible = (element: HTMLElement) => {
    const style = window.getComputedStyle(element)

    return (
      element.offsetWidth > 0 &&
      element.offsetHeight > 0 &&
      style.opacity !== '0' &&
      style.visibility !== 'hidden' &&
      element.getAttribute('tabindex') !== '-1' &&
      !element.hasAttribute('disabled') &&
      !element.classList.contains('disabled')
    )
  }

  /**
   * Traps the focus within the dialog element when navigating with the keyboard.
   *
   * @param {HTMLElement} dialogElement - The dialog element to trap focus within.
   * @param {KeyboardEvent} event - The keyboard event.
   */
  const focusTrap = (dialogElement: HTMLElement, event: KeyboardEvent) => {
    // Get all focusable elements within the dialog element
    const focusableElements = Array.from(
      dialogElement.querySelectorAll<HTMLElement>(
        'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
      )
    ).filter(isVisible)

    // If there are no focusable elements, do nothing
    if (!focusableElements.length) return

    // Destructure the first, last, and currently active elements
    const [firstElement, lastElement, activeElement] = [
      focusableElements[0],
      focusableElements.at(-1)!, // Typescript (!) Non-null assertion operator
      document.activeElement as HTMLElement,
    ]

    // If Shift + Tab is pressed and the active element is the first element, focus the last element
    if (event.shiftKey && activeElement === firstElement) {
      lastElement.focus()
      event.preventDefault()
    }

    // If Tab is pressed and the active element is the last element, focus the first element
    if (!event.shiftKey && activeElement === lastElement) {
      firstElement.focus()
      event.preventDefault()
    }
  }

  return {
    focusTrap,
  }
}
