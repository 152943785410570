import type { AxiosPromise } from 'axios'

import type { GiftcardInfo, CheckoutInfo } from '../api-types/GiftcardInfo'
import BaseApi from '../bridge-api/baseApi'

export class Giftcard extends BaseApi {
  validateGiftcard(code: string): AxiosPromise<GiftcardInfo> {
    return this.http({
      method: 'post',
      url: `/obgateway/v1/giftcard/validate`,
      data: {
        giftcard_code: code,
        channel: 'NEXTORY',
      },
    })
  }

  checkoutGiftcard({
    alternativeBillingDetails = undefined,
    channel = 'NEXTORY',
    gateway = 'GIFTCARD',
    giftcardCode = '',
    subscriptionId = '',
  }: CheckoutInfo) {
    channel = channel || 'NEXTORY'

    return this.http({
      method: 'post',
      url: '/obgateway/v1/register/checkout',
      data: {
        giftcard_code: giftcardCode,
        channel,
        subscription_id: subscriptionId,
        gateway,
        alternative_billing_details:
          alternativeBillingDetails?.external_transaction_token &&
          alternativeBillingDetails?.platform
            ? alternativeBillingDetails
            : undefined,
      },
    })
  }
}
