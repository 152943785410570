import consola from 'consola'
import type { AxiosResponse } from 'axios'
import type Hummingbird from '../hummingbird/core'
import { isSameURL } from './utils'
import Storage from './storage'
import type { Profile as LegacyProfile } from '~/plugins/hummingbird/legacy-api/types/profile'
import type { LoginOptions } from '~/plugins/hummingbird/bridge-api/sessions'

const logger = consola.withTag('$auth')
const TOKEN_KEY = 'token'

export class Auth {
  public ctx: any
  public $storage: null | Storage = null
  public $state: any
  public $hummingbird: null | Hummingbird = null
  private _errorListeners: Function[]

  constructor(ctx: any) {
    this.ctx = ctx

    // Error listeners
    this._errorListeners = []
  }

  setupHummingbird($hummingbird: Hummingbird) {
    this.$hummingbird = $hummingbird
    if (this.$storage) {
      this.$hummingbird?.setupDeviceId(this.$storage)
    }
  }

  setupVuexStore(store: any) {
    const storage = new Storage(store, this.ctx)
    this.$storage = storage
    this.$state = storage.state

    if (this.$hummingbird) {
      this.$hummingbird?.setupDeviceId(this.$storage)
    }
  }

  async init(): Promise<void> {
    this.onError(() => {
      // Reset user on error (invalid or expired token, etc.)
      this.reset()
    })

    try {
      // Call mounted on initial load
      await this.mounted()
    } catch (error: any) {
      this.callOnError(error)
    } finally {
      // Watch for loggedIn changes only in client side
      if (import.meta.client) {
        this.$storage?.watchState('loggedIn', loggedIn => {
          this.redirect(loggedIn ? 'login' : 'logout')
        })
      }
    }
  }

  /**
   * Called on initial load
   *
   * Fetch user from API if token is available
   */
  async mounted(): Promise<void> {
    this.syncToken()
    await this.fetchUserOnce()
  }

  async fetchUser(
    user: AxiosResponse<LegacyProfile> | undefined = undefined
  ): Promise<LegacyProfile | false> {
    // Set token if present
    if (user?.data?.authentication_token) {
      this.setToken(user.data.authentication_token)
    }

    if (!this.$hummingbird) {
      return this.setUser(false)
    }

    const [account, subscription] = await Promise.all([
      !user ? this.$hummingbird?.me.getProfile() : Promise.resolve(user),
      this.$hummingbird.me.getProfileSubscription().catch(() => {
        //   If the user is not subscribed, the API will return an error.
        //   We don't want to break the login process because of this.
        return Promise.resolve({ data: { disallowed_actions: [] } })
      }),
    ])

    const userInfo = {
      ...account.data,
      disallowed_actions: subscription.data.disallowed_actions,
    }

    return this.setUser(userInfo)
  }

  login(data: LoginOptions) {
    const isAlreadyLoggedIn = this.loggedIn

    return this.$hummingbird?.sessions
      .login(data)
      .then(response => {
        const user = this.fetchUser(response)

        // Note:
        //   State won't change too much if we're already logged in, so we need to trigger redirect manually.
        //   This is an edge case from the auto-login: if user comes from a campaign email, its session will be replaced.
        if (isAlreadyLoggedIn) {
          this.redirect('login')
        }

        return user
      })
      .catch(e => {
        // Reset user on error (invalid or expired token, etc.)
        this.reset()

        return Promise.reject(e)
      })
  }

  logout() {
    return this.$hummingbird?.sessions
      .logout()
      .then(response => {
        return response
      })
      .catch(() => {
        // Do nothing - We accept that the logout endpoint might fail
      })
      .finally(() => {
        this.reset()
      })
  }

  reset() {
    this.setUser(false)
    this.setToken(undefined)
  }

  async fetchUserOnce(): Promise<LegacyProfile | false> {
    if (this.getToken() && !this.user) {
      await this.fetchUser()
    }

    return Promise.resolve(this.$state.user)
  }

  setUser(user: LegacyProfile | false): LegacyProfile | false {
    if (user) {
      this.$hummingbird?.setUserMarket(user.country)
    }

    this.$storage?.setState('user', user)
    this.$storage?.setState('loggedIn', Boolean(user))

    return user
  }

  /**
   * Redirects the user to a given route depending on an action or a callback.
   */
  redirect(action: string): void {
    const route = useRoute()
    const redirectCallback = this.getRedirectCallback()

    if (
      redirectCallback?.startsWith(`/${this.ctx.$i18n.locale.value}/collab`) &&
      window?.location?.href
    ) {
      // Special case: if callback is a Strapi V1 URL, Nuxt's redirect/router.push will fail,
      //   because it won't try to reload the page...
      //   This workaround can be removed once we have migrated to Strapi V2.
      logger.info(
        'Special  case: redirecting to partner page',
        redirectCallback
      )

      window.location.href = redirectCallback

      return
    }

    if (redirectCallback) {
      const newPath = this.changeLocale(redirectCallback)
      // Note: A dummy domain is needed to parse relative URLs
      const parsedUrl = new URL(newPath, 'http://mock.example')

      // We wrap the callback URL with a localePath to make sure the destination has a locale.
      // Note:
      //   If the callback already had a locale, it won't be changed.
      //   In practice, this allows to redirect to a different locale,
      //   or to not have to specify a locale at all.
      const { $localePath } = this.ctx
      const to = $localePath({
        path: parsedUrl.pathname,
        hash: route.hash,
        query: Object.fromEntries(parsedUrl.searchParams.entries()),
      })
      logger.info('Redirecting to callback', to)

      navigateTo(to, {
        external: true, // Force full page reload, it avoids issues where a user from market X could access market Y and see incorrect information
      })

      return
    }

    const from = route.path
    const routeMapping = {
      login: this.ctx.$localePath({ name: 'profile-profile' }),
      logout: this.ctx.$localePath({ name: 'index' }),
    } as Record<string, string>
    const to = routeMapping[action]

    if (!to) {
      return
    }

    // Prevent infinite redirects
    if (isSameURL(this.ctx, to, from)) {
      return
    }

    navigateTo(this.changeLocale(to), route.query)
  }

  // ---------------------------------------------------------------
  // State getters
  // ---------------------------------------------------------------

  get loggedIn(): boolean {
    return this.$state.loggedIn
  }

  get user(): Record<string, LegacyProfile | false> | null {
    return this.$state.user
  }

  // ---------------------------------------------------------------
  // Token helpers
  // ---------------------------------------------------------------

  getToken() {
    const token = this.$storage?.getUniversal(TOKEN_KEY) as string

    if (token) {
      this.$hummingbird?.setAuthToken(token)
    }

    return token
  }

  setToken(token?: string) {
    this.$hummingbird?.setAuthToken(token)

    return this.$storage?.setUniversal(TOKEN_KEY, token)
  }

  syncToken() {
    const token = this.$storage?.syncUniversal(TOKEN_KEY) as string

    if (token) {
      this.$hummingbird?.setAuthToken(token)
    }

    return token
  }

  // ------------------------------
  // Utils
  // ------------------------------

  onError(listener: Function) {
    this._errorListeners.push(listener)
  }

  callOnError(error: Error, payload = {}) {
    for (const fn of this._errorListeners) {
      fn(error, payload)
    }
  }

  // ------------------------------
  // Redirect helpers
  // ------------------------------

  getPreferredUserLocale(): string | undefined {
    if (!this.user) {
      return undefined
    }

    const userCountry = this.user.country
    const currentLanguage =
      this.ctx.$i18n.localeProperties.value.language?.split('-')[0]
    const preferredISO = `${currentLanguage}-${userCountry}`
    const locales = this.ctx.$i18n.locales.value as Record<string, string>[]
    let preferredLocale = locales.find(
      locale => locale.language === preferredISO
    )

    if (!preferredLocale) {
      // If not available, get the first locale available for the user's country
      preferredLocale = locales.find(locale =>
        locale.language.endsWith(`-${userCountry}`)
      )
    }

    return preferredLocale?.code
  }

  /**
   * When the user is logged in, we want to redirect them to the same page but in their preferred locale.
   *
   * Example: This is because a user from Sweden can't access the Belgian market, etc.
   */
  changeLocale(path: string) {
    if (!this.user) {
      return path
    }

    const preferredLocale = this.getPreferredUserLocale()
    if (this.ctx.$i18n.locale.value !== preferredLocale) {
      logger.info(
        'Changing locale from',
        this.ctx.$i18n.locale.value,
        'to',
        preferredLocale
      )

      return path.replace(
        `/${this.ctx.$i18n.locale.value}`,
        `/${preferredLocale}`
      )
    } else {
      return path
    }
  }

  setLocalStorageCallback(path: string) {
    localStorage.setItem('cb', encodeURIComponent(path))
  }

  getLocalStorageCallback() {
    const cb = localStorage.getItem('cb')

    if (cb) {
      localStorage.removeItem('cb')
    }

    return cb
  }

  getRedirectCallback(): string | null {
    // Domain is stripped from the callback url to avoid redirecting to another domain
    // @see https://cheatsheetseries.owasp.org/cheatsheets/Unvalidated_Redirects_and_Forwards_Cheat_Sheet.html

    const route = useRoute()
    let callBackPath =
      (route.query.cb as string) || this.getLocalStorageCallback()

    if (!callBackPath) {
      return null
    }

    if (callBackPath.startsWith('/')) {
      // Prepend a dummy domain, so relative URLs can still be parsed by `new URL`
      callBackPath = `https://null${callBackPath}`
    }

    const url = new URL(decodeURIComponent(callBackPath))

    if (Object.keys(route.query).length) {
      // Carry current query params over to the callback url
      for (const [key, value] of Object.entries(route.query)) {
        if (key && key !== 'cb') {
          url.searchParams.append(key, value as string)
        }
      }
    }

    return url.pathname + url.search
  }
}
